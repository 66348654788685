
import { defineComponent } from 'vue'
import Pagination from '@/components/molecules/Pagination.vue'
import SearchContainer from '@/components/molecules/SearchContainer.vue'
// import NoSearchIcon from '@/assets/svg/no-search.svg?inline'
import ChooseUnitTable from '@/components/molecules/table/ChooseUnit.vue'

export default defineComponent({
  name: 'PropertySection',
  data () {
    return {
      unitList: {},
      unitMeta: null,
      page: 1,
      searchData: ''
    }
  },
  mounted () {
    // const lang = JSON.parse(localStorage.getItem('lang'))
    // this.activeLang = lang
    this.getUnits()
  },
  components: {
    Pagination,
    SearchContainer,
    ChooseUnitTable
    // NoSearchIcon
  },
  methods: {
    getUnits (searchData) {
      let url = ''
      url += `?page=${this.page}`
      url += `&search=${this.searchData}`
      this.$store.dispatch('property/getUnits', url).then(
        (res) => {
          this.unitList = res.data
          this.unitMeta = res.meta
          this.isLoading = false
        },
        (error) => {
          this.error = error.response.data.message
          this.isLoading = false
        }
      )
    },
    handlePage (page) {
      this.page = page
      this.getUnits()
    }
  }
})
