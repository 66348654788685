
import { defineComponent } from 'vue'
import Info from '@/assets/svg/info.svg?inline'

export default defineComponent({
  name: 'ChooseUnit',
  data () {
    return {
      publish: false
    }
  },
  props: {
    unitsData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    handleChooseBuilding: {
      type: [Function, Boolean],
      default: false
    }
  },
  components: {
    Info
  },
  methods: {
    chooseUnit (id) {
      this.$router.push(`/dashboard/create-slots?unitId=${id}`)
    }
  }
})
