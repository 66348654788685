import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d806d3fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "choose-unit" }
const _hoisted_2 = { class: "choose-unit__svg-hint" }
const _hoisted_3 = { class: "choose-unit__action-group" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Info = _resolveComponent("Info")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('message.property.building_address')), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('message.property.unit_name')), 1),
          _createElementVNode("th", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('message.actions')) + " ", 1),
            _createVNode(_component_Info, { class: "choose-unit__info" }),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('message.actions_hint')), 1)
          ])
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unitsData, (item) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: item.id
          }, [
            _createElementVNode("td", null, _toDisplayString(item.address), 1),
            _createElementVNode("td", null, _toDisplayString(item.name), 1),
            _createElementVNode("td", _hoisted_3, [
              _createElementVNode("div", {
                class: "choose-unit__button second-button",
                onClick: ($event: any) => (_ctx.chooseUnit(item.id))
              }, _toDisplayString(_ctx.$t('message.choose')), 9, _hoisted_4)
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}